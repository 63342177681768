import React from "react";
import { Container } from "react-bootstrap";

import "./App.css";

export default function App() {
  return (
    <div className="container">
      <Container>
        <h1>KIH - Privacy Policy</h1>
        <p>
          KIH strongly believes in protecting your privacy and the
          confidentiality of your Personal Information. In order to explain how
          KIH collects, uses and protects Personal Information, we have
          developed this Privacy Notice. Personal Information is any combination
          of information, in the possession of or likely to come into the
          possession of KIH, that can be used to identify, contact, or locate an
          individual (“Personal Information”) and will be treated in accordance
          with this Privacy Notice. This includes any expression of opinion
          about such individual. Any information which cannot be used to
          identify an individual (such as aggregated statistical information) is
          not Personal Information. This Privacy Notice covers the information
          practices of websites that link to this Privacy Notice (each referred
          to as a “Site”). The data controller of this website is KIH.
        </p>
        <h1>
          Purpose for Collection, Use and Disclosure of Personal Information{" "}
        </h1>
        <p>
          KIH may collect, use and disclose your Personal Information (including
          individuals associated with you, all of whom are referred to as “you”
          in this Privacy Notice) in its normal course of business for the
          following purposes:
        </p>
        <ul>
          <li>Establishing and maintaining communications with you;</li>
          <li>Arranging insurance cover;</li>
          <li>Handling claims; </li>
          <li>Marketing and client profiling;</li>
          <li>
            Allowing our company to notify you of certain products or services
            offered by our affiliated companies;
          </li>
          <li>Processing transactions through service providers;</li>
          <li>
            Sharing with associations for those clients/participants obtaining
            insurance coverage by being a member of the association;
          </li>
          <li>
            Sharing with financial institutions and other organizations where we
            have joint marketing agreements;
          </li>
          <li>Facilitating premium finance arrangements;</li>
          <li>Credit assessments and other background checks;</li>
          <li>
            Where we believe it is necessary to meet legal, security, processing
            and regulatory requirements;
          </li>
          <li>
            Protecting against fraud, suspicious or other illegal activities;
          </li>
          <li>Research and statistical analysis ; and</li>
          <li>Building databases for use by KIH, its clients and insurers.</li>
        </ul>
        <h1>What Information We Collect</h1>
        <p>
          The information gathered by KIH from this Site falls into two
          categories: (1) information voluntarily supplied by visitors to our
          Site and (2) tracking information gathered as visitors navigate
          through our Site.
        </p>
        <h1>Information Voluntarily Provided By You</h1>
        <p>
          When using this Site, you may choose to provide us with information to
          help us serve your needs. The Personal Information that we collect
          will depend on how you choose to use this Site.
        </p>
        <ul>
          <li>Where you register with us and/or request an insurance quote</li>
        </ul>
        <p>
          If you register with the Site, or request an insurance quote, we may
          ask you for your name, e-mail address, country, telephone number and
          the reason for your communication, as well as information about your
          position and organization and such other information as is reasonably
          necessary so that we can provide you with the quote. On the data
          submission form, we shall indicate by way of an asterisk which
          information is optional and which information is mandatory. This
          information can include:
        </p>
        <ul>
          <li>
            Information you provide on applications or other forms, which may
            include your name, address, email address, age, personal
            identification numbers, medical and health information, employment
            and income information;
          </li>
          <li>
            Information we acquire from and/or transfer to other persons (such
            as government agencies, industry associations, auditors, claims
            adjusters, your insurer and your employer) to verify your identity
            and the accuracy of the information you have provided.
          </li>
          <li>
            Information about you received from KIH, insurers, other
            intermediaries, third party providers and others for underwriting or
            claims purposes (such as previous insurance and claims history)
            about our Clients and Participants; and
          </li>
          <li>Information we receive from consumer reporting agencies.</li>
        </ul>
        <p>
          Any Personal Information that you provide will be used for the purpose
          indicated on the Site or in this Privacy Notice.{" "}
        </p>
        <br />
        <p>
          Following the quote, if you decide to proceed, we will collect
          Personal Information necessary to finalise the relevant quote and
          proceed with the transaction, such as your name, address, post code,
          contact telephone number, e-mail address, billing address or payment
          details, as relevant to the product. We will use your Personal
          Information to administer your policy, process claims and generally
          manage your relationship with us. We may pass the Personal Information
          you provide to others. Please see the Disclosure of Information to
          Others section for information on the categories of recipients of
          Personal Information.
        </p>
        <ul>
          <li>Where you submit content</li>
        </ul>
        <p>
          Finally, if you submit content regarding any of the information that
          you view on our Site, we will ask for your name and e-mail address, so
          that, if you choose, we can update you by e-mail when others also
          comment on the content, and also so that we can manage the content in
          line with our Terms & Conditions. You should be aware that the
          information you provide there will be made publically available to KIH
          employees and other users of the Site.
        </p>
        <h1>Website Navigational Information – Cookies</h1>
        <p>
          As you navigate the Site, we may also collect information through the
          use of commonly-used information-gathering tools, such as cookies and
          web beacons (collectively “Website Navigational Information”). Website
          Navigational Information includes standard information from your web
          browser (such as browser type and browser language), your Internet
          Protocol (“IP”) address, and the actions you take on the Site (such as
          the web pages viewed and the links clicked).
        </p>
        <h1>Disclosure of Information to Others</h1>
        <p>
          We do not disclose any Personal Information about you to any third
          parties except as stated in this Privacy Notice, as otherwise
          permitted by law, or authorised by you.
        </p>
        <br />
        <p>
          Third parties to whom we disclose your Personal Information are
          required by law and contractual undertakings to keep your Personal
          Information confidential and secure, and to use and disclose it for
          purposes that a reasonable person would consider appropriate in the
          circumstances, in compliance with all applicable legislation. The
          purposes for which we may disclose your Personal Information to third
          parties are as follows:
        </p>
        <ul>
          <li>
            To conduct our business as an insurance intermediary and/or risk
            consultant including for the purposes of arranging insurance cover,
            handling claims, facilitating premium finance arrangements in
            respect of which we may disclose your Personal Information to
            insurers, reinsurers, intermediaries or other brokers, outsourcers,
            sub-contractors, agents and service providers, premium finance
            providers and professional advisors and auditors;
          </li>
          <li>
            To associations for those Clients/Participants obtaining insurance
            coverage by being a member of the association;
          </li>
          <li>
            To notify you or allow our affiliated companies to notify you of
            certain products or services offered by our affiliated companies;
            and
          </li>
          <li>
            To update information with credit bureaus and insurance reporting
            agencies.
          </li>
        </ul>
        <p>
          If these third parties wish to use your Personal Information for any
          other purpose, they will have a legal obligation to notify you of this
          and, where required, to obtain your consent.
        </p>
        <h1>Affiliate Sharing</h1>
        <p>
          In the normal course of performing services for our clients, Personal
          Information may be shared with KIH for research and statistical
          purposes, system administration and crime prevention or detection.
          This may require Personal Information to be accessed or moved to a
          country different from the one where the Personal Information was
          collected. When you supply us with information containing third party
          Personal Information (names, addresses, or other information relating
          to living individuals), we will hold and use that Personal Information
          to perform general insurance and other services for you on the
          understanding that the individuals to whom the Personal Information
          relates have been informed of the reason(s) for obtaining the Personal
          Information, the fact that it may be disclosed to third parties such
          as KIH, and have consented to such disclosure and use.
        </p>
        <h1>Other Legally Required Disclosures</h1>
        <p>
          KIH preserves the right to disclose without your prior permission any
          Personal Information about you or your use of this Site if KIH has a
          good faith belief that such action is necessary to: (a) protect and
          defend the rights, property or safety of KIH, employees, other users
          of this Site, or the public; (b) enforce the terms and conditions that
          apply to use of this Site; (c) as required by a legally valid request
          from a competent governmental authority; or (d) respond to claims that
          any content violates the rights of third-parties. We may also disclose
          Personal Information as we deem necessary to satisfy any applicable
          law, regulation, legal process or governmental request.
        </p>
        <p>
          In this Privacy Notice, the purposes identified above and in
          “Collection, Use and Disclosure of Personal Information” will be
          referred to as the “Identified Purposes.”
        </p>
        <h1>Consent</h1>
        <p>
          Your knowledge of and consent to KIH’s collection, use and disclosure
          of your Personal Information is critical. We rely on the following
          actions by you as indications of your consent to our existing and
          future Personal Information practices:
        </p>
        <ul>
          <li>
            Your voluntary provision of Personal Information to us directly or
            indirectly, for example through another insurance intermediary, a
            representative or your employer for the purposes set out above;
          </li>
          <li>
            Your express consent or acknowledgement contained within a written,
            verbal or electronic document; and
          </li>
          <li>
            Your verbal consent solicited by KIH (or our agent) for a specified
            purpose.
          </li>
        </ul>
        <p>
          Where KIH relies on consent for the fair and lawful processing of
          Personal Information, the opportunity to consent will be provided when
          the Personal Information in question is collected. Your consent may be
          given through your authorised representative such as a legal guardian,
          agent or holder of a power of attorney.
        </p>
        <p>
          Subject to certain legal or contractual restrictions and reasonable
          notice, you may withdraw this consent at any time. KIH will inform you
          of the consequences of withdrawing your consent. In some cases,
          refusing to provide certain Personal Information or withdrawing
          consent for KIH to collect, use or disclose your Personal
          Information could mean that we cannot obtain insurance coverage or
          other requested products, services or information for you.
        </p>{" "}
        <p>
          If you wish to withdraw your consent please refer to the Questions or
          To Withdraw Consent sections below.
        </p>{" "}
        <p>
          However, there are a number of instances where KIH does not require
          your consent to engage in the processing or disclosure of Personal
          Information. KIH may not solicit your consent for the processing or
          transfer of Personal information for those purposes which have a
          statutory basis, such as:
        </p>
        <ul>
          <li>
            The transfer or processing is necessary for the performance of a
            contract between you and KIH
          </li>
          <li>
            The transfer or processing is necessary for the performance of a
            contract, concluded in your interest, between KIH and a third party;
          </li>
          <li>
            The transfer or processing is necessary, or legally required, on
            important public interest grounds, for the establishment, exercise,
            or defense of legal claims, or to protect your vital interests; or
          </li>
          <li>The transfer or processing is required by applicable law.</li>
        </ul>
        <h1>Limiting Collection and Retention of Personal Information</h1>{" "}
        <p>
          KIH will collect, use, or disclose Personal Information that is
          necessary for the Identified Purposes or as permitted by law. If we
          require Personal Information for any other purpose, you will be
          notified of the new purpose, and subject to your consent (where
          appropriate), that new purpose will become an Identified Purpose.
        </p>
        <p>
          KIH will collect Personal Information by fair and lawful means. We
          will normally retain Personal Information as long as necessary for the
          fulfillment of the Identified Purposes. However, some Personal
          Information may be retained for longer periods as required by law,
          contract, or auditing requirements.
        </p>{" "}
        <h1>Safeguards</h1>
        <p>
          We have in place physical, electronic and procedural safeguards
          appropriate to the sensitivity of the information we maintain
          regarding Clients and Participants. Safeguards will vary depending on
          the sensitivity, format, location, amount, distribution and storage of
          the Personal Information. They include physical, technical, and
          managerial measures to keep Personal Information protected from
          unauthorized access. Among such safeguards are the encryption of
          communications via SSL, encryption of information while it is in
          storage, firewalls, access controls, separation of duties, and similar
          security protocols. However, due to the nature of the Internet and
          related technology, we cannot absolutely guarantee the security of
          Personal Information, and KIH expressly disclaims any such obligation.
        </p>{" "}
        <h1>External Links</h1>
        <p>
          KIH Sites may include links to other websites whose privacy policies
          we do not control. Once you leave our servers (you can tell where you
          are by checking the URL in the location bar on your web browser), use
          of any Personal Information you provide is governed by the privacy
          notice of the operator of the website you are visiting. That notice
          may differ from ours. If you can't find the privacy notice of any of
          these websites via a link from the website's homepage, you should
          contact the website directly for more information.
        </p>{" "}
        <h1>Accuracy, Accountability, Openness and Customer Access</h1>
        <p>
          It is important that the Personal Information (e.g. email address)
          that we hold about you is accurate, complete and up-to-date. If any of
          your details change you can update us by e-mailing your usual KIH
          contact
        </p>
         <p>Please keep us informed of changes to your Personal Information.</p>{" "}
        <p>
          You have the right to access your Personal Information and request
          rectification of any Personal Information in the file that may be
          obsolete, incomplete or incorrect. However, to secure your Personal
          Information, KIH does not provide online access to its customers to
          view or amend Personal Information in its database.
        </p>{" "}
        <h1>To Withdraw Consent</h1>
        <p>
          You may exercise your right to withdraw your consent to applicable
          uses or disclosures of your Personal Information (which may limit or
          terminate the products and services that KIH provides to you) by
          contacting your usual KIH contact. We will need to validate the
          identity of anyone making such a request to ensure that we do not
          provide your information to anyone who does not have the right to such
          information.
        </p>{" "}
        <h1>Questions, Requests or Complaints</h1>
        <p>
          If you have any questions about this Privacy Notice, want to access
          your Personal Information or wish to make a complaint about this
          Privacy Notice, you can do so by writing to info@kih.com.kw
        </p>
        <h1>Changes to This Privacy Policy</h1>
        <p>
          The Privacy Notice is subject to change at any time. It was last
          changed on December 2023. If we make changes to this Privacy Notice,
          we will update the date it was last changed.  Any changes we make to
          this Privacy Notice become effective immediately when we post the
          revised Privacy Notice on this Site. We recommend that you review this
          Privacy Notice regularly for changes.
        </p>
        <h1>Our Contractual Arrangements with Insurers</h1>
        <p>
          KIH has agreements with certain insurers pursuant to which KIH
          provides services to them and for which KIH is compensated to the
          extent permitted by law and regulation in KUWAIT
        </p>{" "}
        <p>
          {" "}
          Examples include captive management services, claims administration
          services, claims management, insurer consulting services, managing
          general agent and managing general underwriter services and insurance
          brokerage services regarding insurers’ own insurance programs. KIH is
          also party to agreements with insurers that facilitate insurance
          transactions, including agency agreements, agreements to administer
          facilities, and agreements to operate internet-based insurance placing
          facilities.{" "}
        </p>
        <p>
          KIH also routinely enters into various types of agreements with
          insurers that are ancillary to the insurance broking process, or other
          services performed on behalf of clients. Examples include
          confidentiality and nondisclosure agreements for the release of data
          relating to insurance placements, claims handling and loss control
          programs, nondisclosure and/or noncompetition agreements relating to
          the development and disclosure of new insurance products and services,
          and licensing agreements governing access to and use of data
          management systems and databases.
        </p>{" "}
        <h1>Our Compensation</h1>
        <p>
          {" "}
          KIH is compensated in a variety of ways, including commissions and
          fees paid by insurance companies. KIH receive compensation through :
        </p>{" "}
        <p>
          <strong>Retail Commissions</strong>
        </p>
        <br />
        <p>
          A retail commission is paid to KIH by the insurer as a percentage of
          the premium charged to the insured for the policy. The amount of
          commission may vary depending on several factors, including the type
          of insurance product sold and the insurer selected by the client.
          Retail commission rates can vary from transaction to transaction.{" "}
        </p>
        <p>
          <strong>Contingent Commissions</strong>
        </p>
        <p>
          Some insurers agree to pay KIH contingent commissions when we meet set
          goals for insurance policies placed with them during a given year or
          other time period. The set goals may include volume, profitability,
          retention and/or growth thresholds. The amount of contingent
          commission earned may vary depending on factors relating to an entire
          book of business over the course of the year or period. As a result,
          the amount of contingent commission attributable to any given
          insurance policy typically will not be known at the time of placement.
        </p>
      </Container>
    </div>
  );
}
